<template>
  <div class="relative">
    <!-- TODO: Add Tag to Checkbox in full variant  https://backmarket.atlassian.net/browse/DSB-7779 -->
    <slot name="tag" />

    <RevRadio
      :id
      :class="[
        $style.radio,
        {
          [$style.highlighted]: isHighlighted,
        },
      ]"
      :model-value="isSelected"
      :value="id"
      variant="full"
      @click="handleClick"
    >
      <template #label>
        <span class="body-1-bold">{{ offer.title }}</span>

        <template v-if="offer.price && offer.price.amount !== '0.00'">
          <InsurancePrice :is-monthly="offer.isMonthly" :price="offer.price" />
        </template>
      </template>
      <template #description>
        <ul class="mt-8 space-y-8">
          <li
            v-for="benefit in offer.benefits.short"
            :key="benefit"
            class="flex items-center"
          >
            <IconCheckSmall class="shrink-0 self-start" /> {{ benefit }}
          </li>
        </ul>
      </template>
    </RevRadio>
  </div>
</template>

<script setup lang="ts">
import type { InsuranceOffer } from '@backmarket/http-api'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevRadio } from '@ds/components/Radio'
import { IconCheckSmall } from '@ds/icons/IconCheckSmall'

import InsurancePrice from './components/InsurancePrice/InsurancePrice.vue'

const { trackClick } = useTracking()

const props = defineProps<{
  id: string
  offer: InsuranceOffer
  isSelected: boolean
  isHighlighted: boolean
  trackingZone: string
}>()

const emit = defineEmits(['select', 'unselect'])

function handleClick() {
  trackClick({
    name: props.offer.title,
    value: props.offer.isMonthly ? 'monthly' : 'upfront',
    zone: props.trackingZone,
  })

  emit(props.isSelected ? 'unselect' : 'select')
}
</script>

<style lang="css" module>
.radio {
  @apply h-full;
}

.radio > label {
  @apply h-full;
  @apply border-l-6;
  @apply border-l-action-default-low;
}

.radio.highlighted > label {
  @apply border-l-static-success-mid;
}
</style>
