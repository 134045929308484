import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  monthlyPayment: {
    id: 'insurance_monthly_duration',
    defaultMessage: '/month',
  },
  oneTimePayment: {
    id: 'insurance_offer_duration',
    defaultMessage: 'for {duration}',
  },
  cancelAnytime: {
    id: 'insurance_monthly_cancellation_conditions_us',
    defaultMessage: 'cancel anytime',
  },
  oneYearEnrollment: {
    id: 'insurance_monthly_cancellation_conditions',
    defaultMessage: 'for minimum of 1-year ({totalPrice})',
  },
}

export default translations
