<template>
  <RevTag
    v-if="tagLabel"
    class="absolute right-16 top-0 z-[1]"
    :label="tagLabel"
    variant="primary"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevTag } from '@ds/components/Tag'

import translations from './InsuranceTag.translations'

const i18n = useI18n()

const props = defineProps<{
  isMonthly: boolean
  isRecommended: boolean
}>()

const tagLabel = computed(() => {
  if (props.isMonthly) return i18n(translations.monthlyPayment)
  if (props.isRecommended) return i18n(translations.recommended)

  return null
})
</script>
