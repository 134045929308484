<template>
  <div class="flex flex-col gap-12 md:flex-row">
    <InsuranceRadio
      v-for="(offer, index) in offers.filter(
        (offer) => !offer.isCatchUpOffer && !offer.defaultOffer,
      )"
      :id="createIdentifier(offer.id)"
      :key="createIdentifier(offer.id)"
      class="pt-10 only-of-type:w-full"
      :data-qa="`insurance-offer-${index}`"
      :is-highlighted="index === 0"
      :is-selected="selectedOfferId === offer.id"
      :offer
      :tracking-zone
      @select="handleSelect(offer)"
      @unselect="handleSelect(defaultOffer)"
    >
      <template #tag>
        <InsuranceTag
          :is-monthly="hasMonthlyTag(offer)"
          :is-recommended="index === 0 && !hasMonthlyTags(offers)"
        />
      </template>
    </InsuranceRadio>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type InsuranceOffer, MarketCountryCode } from '@backmarket/http-api'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import InsuranceRadio from './components/InsuranceRadio/InsuranceRadio.vue'
import InsuranceTag from './components/InsuranceTag/InsuranceTag.vue'

const { market } = useMarketplace()

const props = defineProps<{
  offers: InsuranceOffer[]
  trackingZone: string
  id: string
  selectedOfferId?: number | string
}>()

const emit = defineEmits<{
  select: [insuranceOffer: InsuranceOffer]
}>()

const defaultOffer = computed(() => {
  if (!props.offers) return null

  return props.offers.find((offer) => offer.defaultOffer)
})

function hasMonthlyTag(offer: InsuranceOffer) {
  return offer.isMonthly && market.countryCode === MarketCountryCode.US
}

function hasMonthlyTags(offers: InsuranceOffer[]) {
  return offers.some(hasMonthlyTag)
}

function handleSelect(offer?: InsuranceOffer | null) {
  if (!offer) return

  emit('select', offer)
}

function createIdentifier(offerId: number | string) {
  return props.id + offerId
}
</script>
