<template>
  <div class="flex flex-row items-center justify-between">
    <FormattedMessage
      class="text-static-default-low body-2 flex h-16 items-center"
      :definition="translations.protectedWith"
      tag="div"
    >
      <template #logo>
        <RevIllustration
          alt="BackUp logo"
          class="ml-4 mt-1"
          :height="16"
          src="/img/insurances/backUpLogo.svg"
          :width="72"
        />
      </template>
    </FormattedMessage>

    <div v-if="withLink">
      <RevLink
        class="body-2-link"
        :tracking="{
          name: 'how_does_it_work',
          zone: trackingZone,
        }"
        @click="handleLinkClicked"
      >
        {{ i18n(translations.learnMore) }}
      </RevLink>

      <ClientOnly>
        <InsuranceModal :name="MODAL_NAMES.BACK_UP_INSURANCE_MODAL" />
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/insurance/constants'

import InsuranceModal from '../InsuranceModal/InsuranceModal.vue'

import translations from './InsuranceProvider.translations'

defineProps<{
  trackingZone: string
  withLink?: boolean
}>()

const i18n = useI18n()

function handleLinkClicked() {
  openModal(MODAL_NAMES.BACK_UP_INSURANCE_MODAL)
}
</script>
