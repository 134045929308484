export default {
  recommended: {
    id: 'insurance_cart_recommended_tag',
    defaultMessage: 'Recommended',
  },
  monthlyPayment: {
    id: 'insurance_cart_monthly_payment_tag',
    defaultMessage: 'Monthly payment',
  },
}
