export default {
  modalName: {
    id: 'cart_insurance_learn_more_modal_name',
    defaultMessage: 'BackUp cover',
  },
  baseLine: {
    id: 'insurance_modal_backup_baseline',
    defaultMessage: 'For the unexpected, get protected',
  },
  warranty: {
    id: 'insurance_modal_comparison_table_warranty',
    defaultMessage: 'Warranty ',
  },
  comparisonTable1: {
    id: 'insurance_modal_comparison_table_benefit1',
    defaultMessage: 'Defects covered ',
  },
  comparisonTable2: {
    id: 'insurance_modal_comparison_table_benefit2',
    defaultMessage: 'Drops, spills, cracks ',
  },
  comparisonTable3: {
    id: 'insurance_modal_comparison_table_benefit3',
    defaultMessage: 'Battery replacement discount',
  },
  comparisonTable4: {
    id: 'insurance_modal_comparison_table_benefit4',
    defaultMessage: 'Refund if we can’t fix it',
  },
  goodDeedTitle: {
    id: 'insurance_modal_title_good_deed',
    defaultMessage: 'Double the good deed',
  },
  goodDeedBody1: {
    id: 'insurance_modal_body_good_deed_1',
    defaultMessage:
      'Not only is prolonging the life of your phone good for the earth, it’ll do some good for your fellow humans.',
  },
  goodDeedBody2: {
    id: 'insurance_modal_body_good_deed_2',
    defaultMessage:
      'In partnership with our insurance providers Cardif and Bolttech, we worked with CFA Ducretet, a refurbishment skills training center, to sponsor a workforce reintegration program that empowers participants to pursue promising careers as professional tech refurbishers.',
  },
  section3Title: {
    id: 'insurance_modal_section_3_title',
    defaultMessage: 'Frequently Asked Questions',
  },
  infoQuestion1: {
    id: 'insurance_modal_infos_question_1',
    defaultMessage: 'What is exactly covered?',
  },
  infoResponse1: {
    id: 'insurance_modal_infos_response_1',
    defaultMessage:
      'With the damage pack, you get a protection against any type of breakage (phone drop, assault, water on the phone etc). With the Damage & Theft pack, you get the same protection with the theft included.',
  },
  infoQuestion2: {
    id: 'insurance_modal_infos_question_2',
    defaultMessage:
      'What are the perks of the popular plan and the fully loaded plan?',
  },
  infoResponse2: {
    id: 'insurance_modal_infos_response_2',
    defaultMessage:
      'We offer more than a simple insurance. Your device is covered against damage and theft even in case of negligence. On top of that, you benefit from extras such as a free ecological case and screen protector and a discount if, at some point, you want to replace your battery with a new one. Also, for every insurance plan sold we give a donation of 1€ to a charity called Wings of the Ocean. This 1€ covers their cost to clean the equivalent of 1 square meter of ocean!',
  },
  infoQuestion3: {
    id: 'insurance_modal_infos_question_3',
    defaultMessage: 'What is excluded from the insurance plans?',
  },
  infoResponse3: {
    id: 'insurance_modal_infos_response_3',
    defaultMessage:
      "Everyone feels like they're getting fleeced when choosing insurance, so we decided to do things a little differently. Our plans do what they say on the box, and they include negligence which is often excluded from standard plans. There are still basic exclusions though (fault must not be intentional, the device has to be used by the insuree, theft is covered but not loss, etc.). Check out the Insurance Product Information Document for full details and don't hesitate to contact us if you have any questions!",
  },
}
