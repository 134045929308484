<template>
  <video
    ref="videoObserverTarget"
    aria-hidden="true"
    :class="[
      'm-auto block w-full',
      { 'rounded-lg h-full': !fullscreen, 'md:aspect-square': fullscreen },
    ]"
    v-bind="attributes"
    muted
    :poster="thumbnail?.src"
    preload="none"
  >
    <source :src="video.url" :type="contentType" />
  </video>
</template>

<script setup lang="ts">
import {
  type MediaHTMLAttributes,
  computed,
  onBeforeUnmount,
  ref,
  watch,
} from 'vue'

import type { Image } from '@backmarket/http-api/src/api-specs-content/models/image'
import type { VideoAssetFile } from '@backmarket/http-api/src/api-specs-content/models/video'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useIntersectionObserver } from '@vueuse/core'

import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'

const props = withDefaults(
  defineProps<
    Pick<ContentBlockProps, 'tracking'> & {
      fullscreen?: boolean
      thumbnail?: Image
      trackImpression?: boolean
      video: VideoAssetFile
    }
  >(),
  {
    fullscreen: false,
    trackImpression: true,
    thumbnail: undefined,
  },
)

const { trackVideoImpression } = useTracking()

const videoObserverTarget = ref<HTMLVideoElement | null>()
const hasTriggeredImpression = ref(false)

const attributes = computed(() =>
  props.fullscreen ? { controls: 'controls' } : { loop: 'loop' },
) as Partial<MediaHTMLAttributes>

const contentType = computed(() =>
  // https://developer.mozilla.org/en-US/docs/Web/Media/Formats/codecs_parameter#iso_base_media_file_format_mp4_quicktime_and_3gp
  props.video.contentType === 'video/quicktime'
    ? 'video/mp4'
    : props.video.contentType,
)

const { stop } = useIntersectionObserver(
  videoObserverTarget,
  ([{ isIntersecting }]) => {
    if (
      isIntersecting &&
      props.trackImpression &&
      typeof props.tracking !== 'undefined'
    ) {
      trackVideoImpression(props.tracking)
      hasTriggeredImpression.value = true
    }
  },
)

watch(videoObserverTarget, () => {
  if (!videoObserverTarget.value || !props.fullscreen) return

  // in some Safari & Chrome versions setting the "autoplay" attribute to true in the template
  // will play multiple video instances instead of one.
  // the load() method is needed for Safari before calling the play() method
  // for the video to start correctly in autoplay mode.
  videoObserverTarget.value.load()
  void videoObserverTarget.value.play()
})

watch(hasTriggeredImpression, () => {
  if (hasTriggeredImpression.value) {
    stop()
  }
})

onBeforeUnmount(() => {
  stop()
})
</script>
