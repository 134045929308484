<template>
  <div class="text-static-default-low caption">
    <span class="body-1 mr-8 inline-block gap-4">
      {{ i18n.price(price) }}

      <template v-if="isMonthly">
        {{ i18n(translations.monthlyPayment) }}
      </template>
    </span>

    <span
      v-if="isInUSMarket"
      class="text-static-default-low caption inline-block"
    >
      {{ i18n(translations.cancelAnytime) }}
    </span>

    <span v-if="!isInUSMarket && isMonthly">
      {{ i18n(translations.oneYearEnrollment, { totalPrice }) }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { MarketCountryCode, type Price } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import translations from './InsurancePrice.translations'

const props = defineProps<{
  price: Price
  isMonthly: boolean
}>()

const marketplace = useMarketplace()
const i18n = useI18n()

const isInUSMarket = computed(
  () => marketplace.market.countryCode === MarketCountryCode.US,
)

const totalPrice = computed(() => {
  return i18n.price({
    amount: (parseFloat(props.price.amount) * 12).toString(),
    currency: props.price.currency,
  })
})
</script>
