export default {
  protectedWith: {
    id: 'insurance_cart_protected_with',
    defaultMessage: 'Protected with {logo, html}',
  },
  learnMore: {
    id: 'insurance_cart_learn_more',
    defaultMessage: 'Learn more about',
  },
}
